import { version } from './version'
declare let window: Window

const testConfig = {
    // Test Secrets
    SF_USERNAME: process.env.SF_USERNAME,
    SF_PASSWORD: process.env.SF_PASSWORD,
    SF_SECURITY_TOKEN: process.env.SF_SECURITY_TOKEN,
    CI_ORG_ID: process.env.CI_ORG_ID,
}

const STAGE = (process.env.stage ||
    process.env.STAGE ||
    process.env.REACT_APP_STAGE ||
    process.env.NX_STAGE ||
    'dev') as 'dev' | 'test' | 'prod'

const serverPort = STAGE === 'prod' ? 4002 : 4000

const clientConfig = {
    SENTRY_APP_DSN: process.env.NX_SENTRY_APP_DSN,
    // SENTRY_CLIENT_DSN:
    //     process.env.REACT_APP_SENTRY_CLIENT_DSN || process.env.NX_SENTRY_CLIENT_DSN || '',
    SF_OAUTH_CLIENT_ID:
        process.env.REACT_APP_SF_OAUTH_CLIENT_ID || process.env.NX_SF_OAUTH_CLIENT_ID,
    NODE_ENV: process.env.NODE_ENV || 'development',
    GRAPHQL_ENDPOINT: '/api/graphql',
    // process.env.REACT_APP_GRAPHQL_ENDPOINT ||
    // process.env.NX_GRAPHQL_ENDPOINT ||
    // (typeof window !== 'undefined' &&
    //     `${window.location.protocol}//${window.location.hostname}:${serverPort}`),
    REPLICACHE_ENDPOINT_PATH:
        process.env.REPLICACHE_ENDPOINT_PATH ||
        process.env.REPLICACHE_ENDPOINT_PATH ||
        typeof window === 'undefined'
            ? null
            : '/api',
    // : window.location.hostname === 'localhost'
    // ? `${window.location.protocol}//${window.location.hostname}:${replicachePort}`
    // : '/api'
    SANDBOX: !!(
        process.env.REACT_APP_SF_SANDBOX ||
        process.env.SF_SANDBOX ||
        process.env.NX_SF_SANDBOX
    ),
    SEGMENT_TOKEN:
        process.env.SEGMENT_TOKEN ||
        process.env.REACT_APP_SEGMENT_TOKEN ||
        process.env.NX_SEGMENT_TOKEN,
    FEEDBACK_FISH_PROJECT: process.env.NX_FEEDBACK_FISH_PROJECT as string,
    HIGHLIGHT_TOKEN: process.env.REACT_APP_HIGHLIGHT_TOKEN || process.env.NX_HIGHLIGHT_TOKEN,
    REPLICACHE_LICENSE_KEY:
        process.env.REACT_APP_REPLICACHE_LICENSE_KEY || process.env.NX_REPLICACHE_LICENSE_KEY,
    RELEASE_COMMIT:
        process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || process.env.VERCEL_GIT_COMMIT_SHA,
}

const applicationConfig = {
    // Apllication Secrets
    SF_OAUTH_CLIENT_SECRET: process.env.SF_OAUTH_CLIENT_SECRET,
    TOKENS_SECRET: process.env.ENCRYPT_TOKEN_KEY,
    AWS_ACCESS_KEY:
        process.env.ZEIT_AWS_ACCESS_KEY ||
        process.env.AWS_ACCESS_KEY ||
        process.env.AWS_ACCESS_KEY_ID,
    AWS_ACCESS_SECRET:
        process.env.ZEIT_AWS_ACCESS_SECRET ||
        process.env.AWS_ACCESS_SECRET ||
        process.env.AWS_SECRET_ACCESS_KEY,
    STAGE,

    REDIS_HOST: process.env.REDIS_HOST,
    REDIS_PORT: process.env.REDIS_PORT && parseInt(process.env.REDIS_PORT),
    REDIS_PASSWORD: process.env.REDIS_PASSWORD,
    // SENTRY_GRAPH_API_DSN:
    //     process.env.REACT_APP_SENTRY_GRAPH_API_DSN || process.env.NX_SENTRY_GRAPH_API_DSN || '',
    COURIER_API_KEY: process.env.COURIER_API_KEY,
    CUSTOMERIO_TRACK_API_KEY: process.env.CUSTOMERIO_TRACK_API_KEY,
    CUSTOMERIO_SITE_ID: process.env.CUSTOMERIO_SITE_ID,
    BACKEND_SEGMENT_TOKEN: process.env.BACKEND_SEGMENT_TOKEN,
    LOGFLARE_API_KEY: process.env.LOGFLARE_API_KEY,
    LOGFLARE_SOURCE_TOKEN: process.env.LOGFLARE_SOURCE_TOKEN,
    STRIPE_API_KEY: process.env.STRIPE_API_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
}

const isTest = process.env.NODE_ENV === 'test'
const isDev = applicationConfig.STAGE === 'dev'
const isProd = applicationConfig.STAGE === 'prod'

const envConfig = {
    API_PORT: serverPort,
    isTest,
    isDev,
    isProd,
}

export const Config = {
    ...testConfig,
    ...clientConfig,
    ...applicationConfig,
    ...envConfig,
    version,
}
