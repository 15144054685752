import {
    init as sentryInit,
    captureException as sentryCaptureException,
    configureScope,
    setUser as sentrySetUser,
    BrowserOptions,
} from '@sentry/react'
import { Dedupe, ExtraErrorData } from '@sentry/integrations'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { Config } from '@laserfocus/shared/config-env'

import { CaptureConsole } from './capture-console'
import { beforeSend } from './fullstory-integration'
import { ReportingStage, CaptureExtraInfo } from './sentry.types'

export function isLocal() {
    return window.location.hostname === 'localhost'
}

export function getConfig() {
    const dsn = isLocal() ? undefined : Config.SENTRY_APP_DSN
    // const dsn = Config.SENTRY_CLIENT_DSN
    const environment = getReportingEnvironment()
    const config: BrowserOptions = {
        dsn,
        environment,
        integrations: [
            new Dedupe(),
            new ExtraErrorData(),
            new TracingIntegrations.BrowserTracing({
                startTransactionOnLocationChange: false,
                startTransactionOnPageLoad: true,
            }),
            new CaptureConsole({ levels: ['error'] }),
        ],
        // To set a uniform sample rate
        tracesSampleRate: 0.1,
        beforeSend,
    }
    return config
}

export function init() {
    sentryInit(getConfig())
}

export function captureException(err: Error, extraInfo?: CaptureExtraInfo) {
    sentryCaptureException(err, extraInfo)
}

type UserContext = SentryUserContext & {
    orgId: string
}

type SentryUserContext = {
    id: string
    username?: string
    email?: string
    ip_address?: string
}

export function setUser(user: UserContext) {
    sentrySetUser({ email: user.email, username: user.username, id: user.id })
    configureScope(function (scope) {
        scope.setTag('orgId', user.orgId)
    })
}

function getReportingEnvironment(): ReportingStage {
    const stagingDomains = ['monolaser.vercel.app', 'laserfocus.vercel.app']

    if (isLocal()) {
        return ReportingStage.DEV
    } else if (Config.STAGE === 'dev' && stagingDomains.includes(window.location.hostname)) {
        return ReportingStage.STAGING
    } else if (Config.STAGE === 'dev') {
        return ReportingStage.DEV
    } else if (window.location.hostname === 'app.laserfocus.io' && Config.STAGE === 'prod') {
        return ReportingStage.PROD
    } else if (Config.STAGE === 'prod') {
        return ReportingStage.STAGING
    }
    throw new Error("ReportingStage can't be found")
}
