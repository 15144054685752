import { parse } from 'querystring'

import { EventProcessor, Hub, Integration, Severity } from '@sentry/types'
import { fill, getGlobalObject, safeJoin } from '@sentry/utils'

const globalScope = getGlobalObject<Window | any>()

/** Send Console API calls as Sentry Events */
export class CaptureConsole implements Integration {
    /**
     * @inheritDoc
     */
    public static id: string = 'CaptureConsole'

    /**
     * @inheritDoc
     */
    public name: string = CaptureConsole.id

    /**
     * @inheritDoc
     */
    private readonly _levels: string[] = ['log', 'info', 'warn', 'error', 'debug', 'assert']

    /**
     * @inheritDoc
     */
    public constructor(options: { levels?: string[] } = {}) {
        if (options.levels) {
            this._levels = options.levels
        }
    }

    /**
     * @inheritDoc
     */
    public setupOnce(_: (callback: EventProcessor) => void, getCurrentHub: () => Hub): void {
        if (!('console' in globalScope)) {
            return
        }

        this._levels.forEach((level: string) => {
            if (!(level in globalScope.console)) {
                return
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            fill(
                globalScope.console,
                level,
                (originalConsoleLevel: () => any) =>
                    (...args: any[]): void => {
                        const hub = getCurrentHub()

                        if (hub.getIntegration(CaptureConsole)) {
                            hub.withScope((scope) => {
                                scope.setLevel(level as Severity)
                                scope.setExtra('arguments', args)
                                scope.addEventProcessor((event) => {
                                    event.logger = 'console'
                                    return event
                                })
                                const { message, context } = parseArgs(args)
                                if (context) {
                                    scope.setExtra('logContext', context)
                                }

                                if (level === 'assert') {
                                    if (args[0] === false) {
                                        const message = `Assertion failed: ${
                                            safeJoin(args.slice(1), ' ') || 'console.assert'
                                        }`
                                        scope.setExtra('arguments', args.slice(1))
                                        hub.captureMessage(message)
                                    }
                                } else if (level === 'error' && args[0] instanceof Error) {
                                    hub.captureException(args[0])
                                } else {
                                    hub.captureMessage(message)
                                }
                            })
                        }

                        // this fails for some browsers. :(
                        if (originalConsoleLevel) {
                            Function.prototype.apply.call(
                                originalConsoleLevel,
                                globalScope.console,
                                args
                            )
                        }
                    }
            )
        })
    }
}

function parseArgs(args: any[]) {
    if (typeof args[0] === 'string' && args[0].startsWith('name=')) {
        // Replicache Error that we dont want to prefix with the clientId
        return {
            message: safeJoin(args.slice(1), ' '),
            context: parse(args[0]),
        }
    }
    return {
        message: safeJoin(args, ' '),
    }
}
