import type { Severity, User } from '@sentry/react'

export enum ReportingStage {
    DEV = 'development',
    STAGING = 'staging',
    PROD = 'production',
}

export type CaptureExtraInfo = Partial<{
    extra: {
        [key: string]: any
    }
    contexts: {
        [key: string]: any
    }
    tags: {
        [key: string]: string
    }
    user: User
    level: Severity
    fingerprint: string[]
}>
