import { EventHint, Event } from '@sentry/react'

declare global {
    interface Window {
        FS: {
            new ()
            getCurrentSessionURL(withTime: boolean): string
            event(name: string, context: Record<string, string | boolean>): void
        }
        _fs_namespace: 'FS'
    }
}

export function beforeSend(event: Event, hint?: EventHint) {
    if (!event || !hint) {
        return
    }
    const error = hint.originalException
    event.extra = event.extra || {}

    var _fs = window[window._fs_namespace]

    if (typeof _fs !== 'function') {
        event.extra.fullstory = 'FullStory is not installed'
        return
    }

    // getCurrentSessionURL isn't available until after the FullStory script is fully bootstrapped.
    // If an error occurs before getCurrentSessionURL is ready, make a note in Sentry and move on.
    // More on getCurrentSessionURL here: https://help.fullstory.com/develop-js/getcurrentsessionurl
    event.extra.fullstory =
        typeof _fs.getCurrentSessionURL === 'function'
            ? _fs.getCurrentSessionURL(true)
            : 'current session URL API not ready'

    // FS.event is immediately ready even if FullStory isn't fully bootstrapped
    let fsEventContext: Record<string, string> = {
        sentryEventId: hint.event_id,
        // Hardcoded for now
        sentryUrl: `https://sentry.io/organizations/laserfocus/issues/?project=3435837946&query=${hint.event_id}`,
    }
    if (error instanceof Error) {
        fsEventContext.name = error.name
        fsEventContext.message = error.message
        fsEventContext.fileName = (error as any).fileName
    }
    _fs.event('Application error', fsEventContext)

    return event
}
