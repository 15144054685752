import { AppProps } from 'next/app'
import Head from 'next/head'

// import '@laserfocus/ui/tailwindcss'
import 'focus-visible'
import './tailwind-in.css'

function CustomApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <meta charSet="utf-8" />
                <meta name="google" content="notranslate" />
                <link rel="apple-touch-icon" href="apple-touch-icon.png" />
                <link rel="icon" type="image/x-icon" href="favicon.png" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta name="theme-color" content="#000000" />
                <title>Laserfocus</title>
            </Head>

            <main className="app">
                <Component {...pageProps} />
            </main>
        </>
    )
}

export default CustomApp
